import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import { normalize } from "styled-normalize"
import { useGlobalContext, useGlobalDispatchContext } from "../context/Context"
import Header from "./Header/Header"
import { cursorStyles } from "../constants/constants"
import CustomCursor from "./CustomCursor/CustomCursor"
import Navigation from "./Navigation/Navigation"

const GlobalStyle = createGlobalStyle`
${normalize}
* {
  text-decoration:none;
 box-sizing:border-box;
 cursor: none;
}

html {
 -webkit-font-smoothing:antialiased;
 font-size:10px; 
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background:${props => props.theme.background};
  overscroll-behavior:none;
  overflow-x:hidden;
}
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const darkTheme = {
    background: "#000",
    text: "#fff",
    red: "#ea291e",
  }

  const lightTheme = {
    background: "#fff",
    text: "#000",
    red: "#ea291e",
  }

  const { currentTheme, cursorType } = useGlobalContext()
  const dispatch = useGlobalDispatchContext()

  const onCursor = newCursorType => {
    newCursorType =
      (cursorStyles.includes(newCursorType) && newCursorType) || false
    dispatch({ type: "TOGGLE_CURSOR", cursorType: newCursorType })
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (
    <ThemeProvider theme={currentTheme === "light" ? lightTheme : darkTheme}>
      <GlobalStyle />
      <Header
        onCursor={onCursor}
        setIsMenuOpen={setIsMenuOpen}
        isMenuOpen={isMenuOpen}
      />
      <Navigation
        onCursor={onCursor}
        setIsMenuOpen={setIsMenuOpen}
        isMenuOpen={isMenuOpen}
      ></Navigation>

      <CustomCursor cursorType={cursorType} isMenuOpen={isMenuOpen} />
      <main>{children}</main>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
