import styled from "styled-components"

export const Cursor = styled.div`
  position: fixed;
  top: 400px;
  left: 400px;
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.red};
  border-radius: 100%;
  transform: translate(-50%, -50%);
  transition: width 0.1s ease-in-out, height 0.1s ease-in-out,
    border 0.2s ease-in-out;
  pointer-events: none;
  z-index: 3;

  &.pointer {
    border: 4px solid ${props => props.theme.text};
  }
  &.hovered {
    background: transparent !important;
    width: 56px;
    height: 56px;
    border: 4px solid ${props => props.theme.red};
  }

  &.nav-open {
    background: ${props => props.theme.text};
    border: 4px solid ${props => props.theme.text};
  }
`
