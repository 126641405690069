import React from "react"
import { HeaderNav, Logo, Menu } from "./header-styles"
import { Container, Flex } from "../styles/globalStyles"
import { Link } from "gatsby"
import {
  useGlobalContext,
  useGlobalDispatchContext,
} from "../../context/Context"

const Header = ({ onCursor, setIsMenuOpen }) => {
  const { currentTheme } = useGlobalContext()
  const dispatch = useGlobalDispatchContext()

  const toggleTheme = () => {
    if (currentTheme === "light") {
      dispatch({ type: "TOGGLE_THEME", theme: "dark" })
    } else {
      dispatch({ type: "TOGGLE_THEME", theme: "light" })
    }
  }

  return (
    <HeaderNav
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -72, opacity: 0 }}
      transition={{ duration: 1.5, ease: [0.44, 1.23, 0.75, 1] }}
    >
      <Container>
        <Flex spaceBetween noHeight>
          <Logo
            onMouseEnter={() => onCursor("hovered")}
            onMouseLeave={() => onCursor("")}
          >
            <Link to="/">FURR</Link>
            <span
              data-testid="toggle-theme"
              onClick={toggleTheme}
              onKeyDown={toggleTheme}
              role="button"
              tabIndex={0}
              aria-label="toggle-theme"
              onMouseEnter={() => onCursor("pointer")}
              onMouseLeave={() => onCursor("hovered")}
            ></span>

            <Link to="/">W{currentTheme}</Link>
          </Logo>
          <Menu
            onClick={() => setIsMenuOpen(state => !state)}
            onMouseEnter={() => onCursor("pointer")}
            onMouseLeave={() => onCursor()}
          >
            <button>
              <span />
              <span />
            </button>
          </Menu>
        </Flex>
      </Container>
    </HeaderNav>
  )
}

export default Header
