import React, { useRef, useEffect } from "react"
import { Cursor } from "./cursor-styles"

const CustomCursor = ({ cursorType, isMenuOpen }) => {
  const cursor = useRef(null)

  const onMouseMove = event => {
    const { clientX, clientY } = event
    cursor.current.style.left = `${clientX}px`
    cursor.current.style.top = `${clientY}px`
  }

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove)
    return () => {
      document.removeEventListener("mousemove", onMouseMove)
    }
  }, [])

  return (
    <>
      <Cursor
        className={`${!!cursorType ? "hovered" : ""} ${cursorType} ${
          isMenuOpen ? "nav-open" : ""
        }`}
        ref={cursor}
      />
    </>
  )
}

export default CustomCursor
